<template>
  <section class="ts-packages-wrapper mb-14">
    <div class="ts-container-fluid container-fluid">
      <div class="ts-packages-wrapper__inner">
        <h1 class="ts-fs-1 ts-text-gray-3-b fw-bold text-center mb-09 mb-lg-11">
          {{ $store.state.subscriptionPage.plansTitle }}
        </h1>
        <nav class="mb-11">
          <div
            class="nav d-block nav-tabs ts-nav-wrapper rounded-pill p-xl-2"
            id="nav-tab"
            role="tablist"
          >
            <div class="ts-tabs-subgroup mb-1 mb-xl-0">
              <button
                class="nav-link rounded-pill active"
                :id="'nav-' + $store.state.subscriptionDurations[1].id + '-tab'"
                data-bs-toggle="tab"
                :data-bs-target="
                  '#nav-' + $store.state.subscriptionDurations[1].id
                "
                type="button"
                role="tab"
                aria-controls="nav-3Months"
                aria-selected="false"
              >
                {{ $store.state.subscriptionDurations[1].attributes.tag }}
              </button>

              <button
                class="nav-link rounded-pill nav-link-elem"
                :id="'nav-' + $store.state.subscriptionDurations[2].id + '-tab'"
                data-bs-toggle="tab"
                :data-bs-target="
                  '#nav-' + $store.state.subscriptionDurations[2].id
                "
                type="button"
                role="tab"
                aria-controls="nav-6Months"
                aria-selected="false"
              >
                {{ subscriptionTagParts.mainText }} |
                <span class="nav-link-sub-container nav-link-sub-font">
                  &nbsp;{{ subscriptionTagParts.discountParts[0] }}
                  <span class="nav-link-sub-font">{{
                    subscriptionTagParts.discountParts[1]
                  }}</span>
                </span>
              </button>
              <button
                class="nav-link rounded-pill nav-link-elem"
                :id="'nav-' + $store.state.subscriptionDurations[3].id + '-tab'"
                data-bs-toggle="tab"
                :data-bs-target="
                  '#nav-' + $store.state.subscriptionDurations[3].id
                "
                type="button"
                role="tab"
                aria-controls="nav-annual"
                aria-selected="false"
              >
                {{ subscriptionTagPartsAnnual.mainText }} |
                <span class="nav-link-sub-container nav-link-sub-font">
                  &nbsp;{{ subscriptionTagPartsAnnual.discountParts[0] }}
                  <span class="nav-link-sub-font">{{
                    subscriptionTagPartsAnnual.discountParts[1]
                  }}</span>
                </span>
              </button>
            </div>
          </div>
        </nav>
        <div class="tab-content" id="packagesGroup">
          <div
            class="tab-pane fade show active"
            :id="'nav-' + $store.state.subscriptionDurations[1].id"
            role="tabpanel"
            :aria-labelledby="
              'nav-' + $store.state.subscriptionDurations[1].id + '-tab'
            "
          >
            <div class="d-none d-md-block d-xl-none">
              <swiper
                :slidesPerView="1"
                :spaceBetween="16"
                :pagination="{
                  clickable: true,
                }"
                :modules="modules"
                :breakpoints="{
                  '767': {
                    slidesPerView: 2,
                    spaceBetween: 28,
                  },
                  '1200': {
                    slidesPerView: 3,
                    spaceBetween: 36,
                  },
                }"
                class="ts-custom-swiper mySwiper py-1 px-1"
              >
                <swiper-slide
                  class="mb-4"
                  v-for="card in month3monthsFilteredCards"
                  :key="card.id"
                >
                  <div class="ts-pricing-card">
                    <div>
                      <Package
                        :title="card.attributes.title"
                        :popular="card.attributes.popular"
                        :description="card.attributes.description"
                        :startat="card.attributes.startat"
                        :currency="card.attributes.currency"
                        :price="card.attributes.price"
                        :month="card.attributes.month"
                        :priceDetails="card.attributes.priceDetails"
                        :priceDetailsBold="card.attributes.priceDetailsBold"
                        :Btntext="card.attributes.GetStartedButton.text"
                        :BtnLink="card.attributes.GetStartedButton.to"
                        :featureList="card.attributes.featureList"
                      />
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div class="d-md-none d-xl-block">
              <div class="row row-cols-xl-3 gap-3 gap-xl-0">
                <div
                  v-for="card in month3monthsFilteredCards"
                  :key="card.id"
                  class="ts-pricing-card"
                >
                  <Package
                    :title="card.attributes.title"
                    :popular="card.attributes.popular"
                    :description="card.attributes.description"
                    :startat="card.attributes.startat"
                    :currency="card.attributes.currency"
                    :price="card.attributes.price"
                    :month="card.attributes.month"
                    :priceDetails="card.attributes.priceDetails"
                    :priceDetailsBold="card.attributes.priceDetailsBold"
                    :Btntext="card.attributes.GetStartedButton.text"
                    :BtnLink="card.attributes.GetStartedButton.to"
                    :featureList="card.attributes.featureList"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            :id="'nav-' + $store.state.subscriptionDurations[2].id"
            role="tabpanel"
            :aria-labelledby="
              'nav-' + $store.state.subscriptionDurations[2].id + '-tab'
            "
          >
            <div class="d-none d-md-block d-xl-none">
              <swiper
                :slidesPerView="1"
                :spaceBetween="16"
                :pagination="{
                  clickable: true,
                }"
                :modules="modules"
                :breakpoints="{
                  '767': {
                    slidesPerView: 2,
                    spaceBetween: 28,
                  },
                  '1200': {
                    slidesPerView: 3,
                    spaceBetween: 36,
                  },
                }"
                class="ts-custom-swiper mySwiper py-1 px-1"
              >
                <swiper-slide
                  class="mb-4"
                  v-for="card in month6monthsFilteredCards"
                  :key="card.id"
                >
                  <div class="ts-pricing-card">
                    <div>
                      <Package
                        :title="card.attributes.title"
                        :popular="card.attributes.popular"
                        :description="card.attributes.description"
                        :startat="card.attributes.startat"
                        :currency="card.attributes.currency"
                        :price="card.attributes.price"
                        :month="card.attributes.month"
                        :priceDetails="card.attributes.priceDetails"
                        :priceDetailsBold="card.attributes.priceDetailsBold"
                        :Btntext="card.attributes.GetStartedButton.text"
                        :BtnLink="card.attributes.GetStartedButton.to"
                        :featureList="card.attributes.featureList"
                      />
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div class="d-md-none d-xl-block">
              <div class="row row-cols-xl-3 gap-3 gap-xl-0">
                <div
                  v-for="card in month6monthsFilteredCards"
                  :key="card.id"
                  class="ts-pricing-card"
                >
                  <Package
                    :title="card.attributes.title"
                    :popular="card.attributes.popular"
                    :description="card.attributes.description"
                    :startat="card.attributes.startat"
                    :currency="card.attributes.currency"
                    :price="card.attributes.price"
                    :month="card.attributes.month"
                    :priceDetails="card.attributes.priceDetails"
                    :priceDetailsBold="card.attributes.priceDetailsBold"
                    :Btntext="card.attributes.GetStartedButton.text"
                    :BtnLink="card.attributes.GetStartedButton.to"
                    :featureList="card.attributes.featureList"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            :id="'nav-' + $store.state.subscriptionDurations[3].id"
            role="tabpanel"
            :aria-labelledby="
              'nav-' + $store.state.subscriptionDurations[3].id + '-tab'
            "
          >
            <div class="d-none d-md-block d-xl-none">
              <swiper
                :slidesPerView="1"
                :spaceBetween="16"
                :pagination="{
                  clickable: true,
                }"
                :modules="modules"
                :breakpoints="{
                  '767': {
                    slidesPerView: 2,
                    spaceBetween: 28,
                  },
                  '1200': {
                    slidesPerView: 3,
                    spaceBetween: 36,
                  },
                }"
                class="ts-custom-swiper mySwiper py-1 px-1"
              >
                <swiper-slide
                  class="mb-4"
                  v-for="card in month12monthsFilteredCards"
                  :key="card.id"
                >
                  <div class="ts-pricing-card">
                    <div>
                      <Package
                        :title="card.attributes.title"
                        :popular="card.attributes.popular"
                        :description="card.attributes.description"
                        :startat="card.attributes.startat"
                        :currency="card.attributes.currency"
                        :price="card.attributes.price"
                        :month="card.attributes.month"
                        :priceDetails="card.attributes.priceDetails"
                        :priceDetailsBold="card.attributes.priceDetailsBold"
                        :Btntext="card.attributes.GetStartedButton.text"
                        :BtnLink="card.attributes.GetStartedButton.to"
                        :featureList="card.attributes.featureList"
                      />
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div class="d-md-none d-xl-block">
              <div class="row row-cols-xl-3 gap-3 gap-xl-0">
                <div
                  v-for="card in month12monthsFilteredCards"
                  :key="card.id"
                  class="ts-pricing-card"
                >
                  <Package
                    :title="card.attributes.title"
                    :popular="card.attributes.popular"
                    :description="card.attributes.description"
                    :startat="card.attributes.startat"
                    :currency="card.attributes.currency"
                    :price="card.attributes.price"
                    :month="card.attributes.month"
                    :priceDetails="card.attributes.priceDetails"
                    :priceDetailsBold="card.attributes.priceDetailsBold"
                    :Btntext="card.attributes.GetStartedButton.text"
                    :BtnLink="card.attributes.GetStartedButton.to"
                    :featureList="card.attributes.featureList"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import Icon from "../../assets/icons/index.vue";
import Package from "@/components/Subscriptions/Packages/CardDetails.vue";
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";
export default {
  components: {
    Package,
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
  computed: {
    subscriptionTagParts() {
      const tag = this.$store.state.subscriptionDurations[2].attributes.tag;
      const parts = tag.split("|").map((part) => part.trim());
      const discountParts = this.splitDiscount(parts[1]);
      return {
        mainText: parts[0],
        discountParts,
      };
    },
    subscriptionTagPartsAnnual() {
      const tag = this.$store.state.subscriptionDurations[3].attributes.tag;
      const parts = tag.split("|").map((part) => part.trim());
      const discountParts = this.splitDiscount(parts[1]);
      return {
        mainText: parts[0],
        discountParts,
      };
    },
    month1FilteredCards() {
      // Filter the cards based on the condition
      return this.$store.state.subscriptionPlans.filter((card) => {
        return (
          card.attributes.subscriptions_duration.data.attributes.tag ===
          this.$store.state.subscriptionDurations[0].attributes.tag
        );
      });
    },
    month3monthsFilteredCards() {
      // Filter the cards based on the condition
      return this.$store.state.subscriptionPlans.filter((card) => {
        return (
          card.attributes.subscriptions_duration.data.attributes.tag ===
          this.$store.state.subscriptionDurations[1].attributes.tag
        );
      });
    },
    month6monthsFilteredCards() {
      // Filter the cards based on the condition
      return this.$store.state.subscriptionPlans.filter((card) => {
        return (
          card.attributes.subscriptions_duration.data.attributes.tag ===
          this.$store.state.subscriptionDurations[2].attributes.tag
        );
      });
    },
    month12monthsFilteredCards() {
      // Filter the cards based on the condition
      return this.$store.state.subscriptionPlans.filter((card) => {
        return (
          card.attributes.subscriptions_duration.data.attributes.tag ===
          this.$store.state.subscriptionDurations[3].attributes.tag
        );
      });
    },
  },

  data() {
    return {
      subscriptionTag: "",
      subtag: "",
      packageData: {
        title: "Essential",
        description:
          "Features include access to creators and searching/connecting with them",
        currency: "AED",
        price: "5,999",
        month: "month",
        priceDetails: "AED 17,997 every 3 months",
        Btntext: "GetStarted",
        BtnLink: "link",
        featureList: [
          "Up to 3 Instagram stories per creator.",
          "Your message on all generated content.",
          "1 public review per creator.",
          "Transparent Campaign reports.",
          "Free campaign consultation.",
        ],
      },
    };
  },
  created() {
    this.subscriptionTag =
      this.$store.state.subscriptionDurations[2].attributes.tag;
    this.subtag = "6 Months | 10%OFF";
  },

  methods: {
    sortedSubscriptionPlans(durationTag) {
      return this.$store.state.subscriptionPlans
        .filter(
          (card) =>
            card.attributes.subscriptions_duration.data.attributes.tag ===
            durationTag
        )
        .sort((a, b) => {
          const orderA =
            a.attributes.subscriptions_duration.data.attributes.cardOrder;
          const orderB =
            b.attributes.subscriptions_duration.data.attributes.cardOrder;
          return orderA - orderB;
        });
    },
    splitDiscount(discountText) {
      const match = discountText.match(/(\d+%)(.*)/);
      return match ? [match[1], match[2].trim()] : [discountText];
    },
  },

  props: {
    title: String,
    imageUrl: String,
    date: String,
    generatedBy: String,
    id: String,
  },
};
</script>

<style lang="scss" scoped>
.ts-packages-wrapper {
  &__inner {
    max-width: 1196px;
    margin-inline: auto;
  }
  margin-bottom: clamp(70px, 8vw, 102px);
}
.ts-addon-card-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 12px;
  }
}

.ts-nav-wrapper {
  @media (min-width: 1201px) {
    -webkit-box-shadow: inset 0 0 5px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: inset 0 0 5px 0px rgba(0, 0, 0, 0.16);
    box-shadow: inset 0 0 5px 0px rgba(0, 0, 0, 0.16);
  }
}
.ts-tabs-subgroup {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.nav {
  @media (min-width: 1201px) {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr);
    // padding-inline: 1rem;
    // width: calc(100% - 16px);
  }
  &-tabs {
    border: 0;

    // @media (min-width: 1201px) {
    //   -webkit-box-shadow: inset 0 0 5px 0px rgba(0, 0, 0, 0.16);
    //   -moz-box-shadow: inset 0 0 5px 0px rgba(0, 0, 0, 0.16);
    //   box-shadow: inset 0 0 5px 0px rgba(0, 0, 0, 0.16);
    // }
  }
  &-link {
    padding-inline: 0 !important;
    font-weight: 500;
    /*font-weight: bold;*/
    color: #989898;
    &:hover,
    &.active {
      background: #00a4b6 !important;
      color: white !important;
      /* font-weight: bold !important;*/
      font-family: "Greycliff CF" !important;
    }
  }

  @media (min-width: 420px) {
    &-link {
      font-size: clamp(1.171875rem, 3.75vw, 1.875rem);
    }
  }
  @media (min-width: 1024px) {
    &-link {
      font-size: clamp(0.9609375rem, 3.075vw, 1.5375rem);
    }
  }
  @media (min-width: 1600px) {
    &-link {
      font-size: clamp(0.816796875rem, 2.61375vw, 1.306875rem);
    }
  }
}

.ts-tabs-subgroup {
  // width: 100% !important;
  @media (max-width: 1200px) {
    -webkit-box-shadow: inset 0 0 5px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: inset 0 0 5px 0px rgba(0, 0, 0, 0.16);
    box-shadow: inset 0 0 5px 0px rgba(0, 0, 0, 0.16);
    border-radius: 50rem;
    padding: 0.5rem;
  }
}

.ts-pricing-card {
  &:empty {
    display: none;
  }
}

.nav-link-sub-font {
  color: inherit;
}
// modifications
.trial-text-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.trial-text {
  margin-top: 3rem;
  width: 85%;
  font-size: clamp(0.9375rem, 20px, 1.5rem);
}
.trial-hyper-link {
  color: #00a4b6;
  font-family: "Greycliff CF Demi";
  font-weight: 600;
  font-style: normal;
}
.trial-button-container {
  display: none;
}
@media (max-width: 768px) {
  .nav-link-elem {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav-link-sub-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .nav-link-sub-font {
    font-size: clamp(0.79734375rem, 2.5515vw, 1.27575rem);
    line-height: 0.9;
  }
  .trial-text {
    margin-bottom: 1.5rem;
  }
  /*.trial-button-container {
    display: flex;
    justify-content: center;
  }
  .trial-button {
    width: 85%;
  }*/
}

@media (max-width: 420px) {
  .nav-link-sub-font {
    font-size: clamp(0.69103125rem, 2.2113vw, 1.10565rem);
  }
}
</style>
