<template>
  <section class="ts-plans-wrapper mb-14">
    <div class="ts-container-fluid container-fluid">
      <h1 class="ts-fs-1 ts-text-gray-5 fw-bold text-center mb-3 mb-md-1">
        {{ $store.state.subscriptionPage.PlansOverview.title }}
      </h1>
      <h2
        class="ts-fs-3-iii ts-text-gray-5 fw-light text-center mb-08 mb-lg-12"
      >
        {{ $store.state.subscriptionPage.PlansOverview.description }}
      </h2>

      <ul class="list-unstyled ts-plan-table">
        <li class="ts-plan-table__row ts-plan-table__head d-none d-md-grid">
          <h3 class="fw-semibold ts-text-gray-4-ii mb-0 ts-text-primary-1">
            {{ $store.state.subscriptionPage.PlansOverview.features }}
          </h3>
          <h3 class="fw-semibold ts-text-gray-4-ii mb-0 text-center">
            {{ $store.state.subscriptionPage.PlansOverview.premium }}
          </h3>
          <h3 class="fw-semibold ts-text-gray-4-ii mb-0 text-center">
            {{ $store.state.subscriptionPage.PlansOverview.pro }}
          </h3>
          <h3 class="fw-semibold ts-text-gray-4-ii mb-0 text-center">
            {{ $store.state.subscriptionPage.PlansOverview.enterprise }}
          </h3>
        </li>
        <li
          class="ts-plan-table__row"
          v-for="planItem in $store.state.subscriptionPage.PlansOverview
            .subscriptionPlanList"
          :key="planItem.id"
        >
          <p class="first-elem mb-0 ts-text-gray-5-i text-center text-md-start">
            {{ planItem.description }}
          </p>
          <div class="text-center">
            <h3
              class="fw-semibold ts-text-gray-4-ii mb-0 text-center d-md-none"
            >
              {{ $store.state.subscriptionPage.PlansOverview.essential }}
            </h3>

            <Icon v-if="planItem.essential" name="check-circle-filled-lg" />
            <Icon v-else name="x-circle-filled-lg" />
          </div>
          <div class="text-center">
            <h3
              class="fw-semibold ts-text-gray-4-ii mb-0 text-center d-md-none"
            >
              {{ $store.state.subscriptionPage.PlansOverview.premium }}
            </h3>
            <Icon v-if="planItem.premium" name="check-circle-filled-lg" />
            <Icon v-else name="x-circle-filled-lg" />
          </div>
          <div class="text-center">
            <h3
              class="fw-semibold ts-text-gray-4-ii mb-0 text-center d-md-none"
            >
              {{ $store.state.subscriptionPage.PlansOverview.pro }}
            </h3>
            <Icon v-if="planItem.pro" name="check-circle-filled-lg" />
            <Icon v-else name="x-circle-filled-lg" />
          </div>
        </li>
      </ul>
    </div>
    <div>
      <div class="trial-text-container">
        <div class="text-center trial-text">
          {{ $store.state.subscriptionPage.trailDaysFirstPart }}
          <span>
            <a class="trial-hyper-link" href="#" @click.prevent="openModal">
              {{ $store.state.subscriptionPage.ClickHere }}
            </a>
          </span>
          {{ $store.state.subscriptionPage.trailDaysLastPart }}
        </div>
        <div v-if="isModalVisible" class="modal-overlay" @click="closeModal">
          <div class="modal-content" @click.stop>
            <div class="ts-container-fluid container-fluid">
              <nav class="subscription-popup-nav-container">
                <div class="subscription-popup-title-container">
                  <div class="p-2">
                    <div class="subscription-popup-title">
                      {{ $store.state.subscriptionPage.titleNormalText }}
                      <span class="subscription-popup-demibole">{{
                        $store.state.subscriptionPage.titleBoldText
                      }}</span>
                    </div>
                  </div>
                </div>
              </nav>
              <div class="tab-content">
                <div class="tab-pane fade show active">
                  <div class="d-none d-md-block d-xl-none">
                    <swiper
                      :slidesPerView="1"
                      :spaceBetween="16"
                      :pagination="{
                        clickable: true,
                      }"
                      :modules="modules"
                      :breakpoints="{
                        '767': {
                          slidesPerView: 2,
                          spaceBetween: 28,
                        },
                        '1200': {
                          slidesPerView: 3,
                          spaceBetween: 36,
                        },
                      }"
                      class="ts-custom-swiper mySwiper py-1 px-1"
                    >
                      <swiper-slide
                        class="mb-4"
                        v-for="card in month1FilteredCards"
                        :key="card.id"
                      >
                        <div class="ts-pricing-card">
                          <div>
                            <Package
                              :title="card.attributes.title"
                              :popular="card.attributes.popular"
                              :description="card.attributes.description"
                              :startat="card.attributes.startat"
                              :currency="card.attributes.currency"
                              :price="card.attributes.price"
                              :month="card.attributes.month"
                              :priceDetails="card.attributes.priceDetails"
                              :priceDetailsBold="
                                card.attributes.priceDetailsBold
                              "
                              :Btntext="card.attributes.GetStartedButton.text"
                              :BtnLink="card.attributes.GetStartedButton.to"
                              :featureList="card.attributes.featureList"
                            />
                          </div>
                        </div>
                      </swiper-slide>
                    </swiper>
                  </div>
                  <div class="d-md-none d-xl-block">
                    <div class="row row-cols-xl-3 gap-3 gap-xl-0">
                      <div
                        v-for="card in month1FilteredCards"
                        :key="card.id"
                        class="ts-pricing-card"
                      >
                        <Package
                          :title="card.attributes.title"
                          :popular="card.attributes.popular"
                          :description="card.attributes.description"
                          :startat="card.attributes.startat"
                          :currency="card.attributes.currency"
                          :price="card.attributes.price"
                          :month="card.attributes.month"
                          :priceDetails="card.attributes.priceDetails"
                          :priceDetailsBold="card.attributes.priceDetailsBold"
                          :Btntext="card.attributes.GetStartedButton.text"
                          :BtnLink="card.attributes.GetStartedButton.to"
                          :featureList="card.attributes.featureList"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span class="modal-text-center">
              {{ $store.state.subscriptionPage.descriptionPopUp }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Icon from "@/assets/icons/index.vue";
import Package from "@/components/Subscriptions/Packages/CardDetails.vue";
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";
export default {
  components: {
    Icon,
    Package,
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
  computed: {
    month1FilteredCards() {
      // Filter the cards based on the condition
      return this.$store.state.subscriptionPlans.filter((card) => {
        return (
          card.attributes.subscriptions_duration.data.attributes.tag ===
          this.$store.state.subscriptionDurations[0].attributes.tag
        );
      });
    },
  },
  data() {
    return {
      isModalVisible: false,
      isSecondaryModalVisible: false,
    };
  },
  methods: {
    openModal() {
      this.isModalVisible = true;
      document.body.classList.add("modal-open");
    },
    closeModal() {
      this.isModalVisible = false;
      document.body.classList.remove("modal-open");
    },
  },
};
</script>

<style lang="scss" scoped>
.ts-plans-wrapper {
  margin-bottom: 4rem;
}
.ts-plan-table {
  border-radius: 11px;
  overflow: hidden;

  -webkit-box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.16);

  &__head {
    // background-color: #f6f6f6;
    * {
      font-size: clamp(20px, 4vw, 27px) !important;
    }
  }
  &__row {
    display: grid;
    grid-template-columns: 45% 1fr 1fr 1fr;
    padding-block: 1rem;
    padding-left: clamp(20px, 5vw, 70px);

    @media (max-width: 767px) {
      grid-template-columns: repeat(3, 1fr);
      padding-inline: 12px;
      gap: 0.75rem;
      .first-elem {
        grid-column: span 4; /* Span full width */
      }
    }
    * {
      font-size: clamp(19px, 4vw, 26px);
    }

    &:nth-child(odd) {
      background-color: #f6f6f6;
    }

    svg {
      width: 28px !important;
    }
  }
}

/* trial text */
.trial-text-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.trial-text {
  margin-top: 3rem;
  width: 85%;
  font-size: clamp(0.9375rem, 20px, 1.5rem);
}
.trial-hyper-link {
  color: #00a4b6;
  font-family: "Greycliff CF Demi";
  font-weight: 600;
  font-style: normal;
}
.trial-button-container {
  display: none;
}
@media (max-width: 767px) {
  .ts-plans-wrapper {
    margin-bottom: 3.5rem;
  }
  .trial-text {
    width: 95%;
    font-size: 16px;
  }
}
@media (max-width: 400px) {
  .trial-text {
    font-size: 15px;
  }
}

/* Modal */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1030;
  width: 100%;
  overflow: hidden;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Custom scrollbar styles */
.modal-content {
  background: white;
  width: 80%;
  border-radius: 15px;
  padding-top: 10px; /* Adjust the padding as needed */
  padding-bottom: 10px; /* Adjust the padding as needed */

  @media (max-width: 1200px) {
    width: 90%;
  }
  @media (max-width: 768px) {
    max-height: 80%; /* Adjust this value as needed */
    overflow-y: auto; /* Enables vertical scrolling */
  }

  /* Custom scrollbar for WebKit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    margin-top: 20px; /* Adjust the margin to push scrollbar away from the top */
    margin-bottom: 20px; /* Adjust the margin to push scrollbar away from the bottom */
  }

  &::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Roundness of the scrollbar thumb */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb on hover */
  }
}
@-moz-document url-prefix() {
  .modal-content {
    scrollbar-width: thin; /* Width of the scrollbar */
    scrollbar-color: #888 white; /* Color of the scrollbar thumb and track */
    margin-top: 20px; /* Adjust the margin to push scrollbar away from the top */
    margin-bottom: 20px; /* Adjust the margin to push scrollbar away from the bottom */
  }
}
.subscription-popup-nav-container {
  margin: 20px 0;
}
.subscription-popup-title-container {
  display: flex;
  justify-content: center;
}
.subscription-popup-title {
  text-align: center;
  font-size: clamp(1.2rem, 4vw, 1.5rem);
  font-family: "Greycliff CF";
  padding: 10px 20px;
}
.subscription-popup-demibole {
  font-weight: 600;
}
/*
.ts-container-fluid {
  max-height: 100%; 
  overflow-y: auto; 
}*/
.modal-text-center {
  margin: 20px 0;
  text-align: center;
}
</style>
